<template lang="pug">
header.editor-header
  nuxt-link(to="/")
    logo-vook-svg
      title Vook
  .how-to
    a(href="https://vookinc.notion.site/Vook-137bbd5c797d80ea972df15728910660" target="_blank")
      icon-question-svg
      | 記事エディターの使い方はこちら
</template>
<script>
import LogoVookSvg from '~/assets/images/svg/logo_vook.svg?inline'
import IconQuestionSvg from '~/assets/images/svg/icon_question.svg?inline'

export default {
  components: {
    LogoVookSvg,
    IconQuestionSvg
  }
}
</script>
<style lang="scss" scoped>
.editor-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  background: #fff;
  height: $simple_header_height;
  line-height: 0;
  position: relative;
  @include media(pc) {
    justify-content: center;
  }
  @include media(sp) {
    padding: 0 10px;
    justify-content: space-between;
  }
  svg {
    width: 60px;
    height: 21px;
    fill: #000;
  }
  .how-to {
    @include media(pc) {
      position: absolute;
      top: 0;
      right: 10px;
      padding-top: calc((#{$simple_header_height} - 32px) / 2);
    }
    a {
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      gap: 5px;
      color: $violet;
      padding: 0px 10px 0px 6px;
      border: solid 1px $violet;
      border-radius: 100px;
      font-size: 12px;
      height: 32px;
      line-height: 30px;
    }
    svg {
      width: 24px;
      circle {
        stroke: $violet;
        fill: transparent;
      }
      path {
        stroke: $violet;
        fill: transparent;
      }
    }
  }
}
</style>
